@import '../../../App.scss';

.Available{
    color: #222;
    background-color: rgba(48, 235, 48, 0.523);
    padding: 5px;
    border-radius: 8px;
}
.notAvailable{
    color: #222;
    background-color: rgba(240, 68, 71, 0.523);
    padding: 5px;
    border-radius: 8px;
}

.two-box{

    .btn-filter{
        background-color: #7a3f15 !important;
        color: #fff;
        padding: 4px 13px;
        margin-right: 10px;
        @media (max-width:450px) {
            margin-top: 10px;
        }
        // margin-top: 10px;
    }

    .filter-box.filter-box-show{
        opacity: 1;
        transition: all 0.6s ease-in-out;
        right: 10px !important;
        top: 20px !important;
        box-shadow: 0px 1px 16px 1px rgba(10, 4, 3, 0.452);
    }
    
    .filter-box{
        transition: all 0.3s ease-in-out;
        position: absolute;
        right: 10px !important;
        top: -600px !important;
        opacity: 0;
        width: 430px !important;
        padding: 15px;
        background-color: #fff;
        min-height: 20%;
        z-index: 999999;
        .header-filter{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            h6{
                color: #233a80;
                font-weight: 700;
            }
            .icon-filter{
                    color: #233a80;
                    cursor: pointer;
            }
        }
        .filter-price-show,
        .filter-status-show,
        .filter-featured-show,
        .filter-A-Z-show,.filter-category-show{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            span{
                font-size: 18px;
            }
            input{
                width: 20px;
                height: 20px;
            }
        }
        .radio-inp{
            display: flex;
            justify-content: center;
            align-items: center;
            gap : 30px;
            margin-bottom: 20px;
            color: gray;
            
        }
        .btn-list{
            display: flex;
            justify-content: center;
            align-content: center;
            flex-wrap: wrap;
            margin-top: 40px;
           
        .btn-reset{
            background-color: crimson;
            color: #fff;
            padding: 4px 13px;
            margin-right: 10px;
        }.btn-reset:hover{
            background-color: rgb(242, 54, 92);
        }
        .btn-apply{
            background-color: $main-color;
            color: #fff;
            padding: 4px 13px;
            margin-right: 10px;
        }.btn-apply:hover{
            background-color: $main-color;
        }
    
    }
    }
}