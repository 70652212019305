.message-box{
margin-top: 10px;
margin-bottom: 10px;
font-size: 17px;
    .Data{
        border: none !important;
        border-bottom: 1px solid lightgray !important;
        padding-bottom: 2px;
    }
    .static{
        color: gray;
        margin: 0 10px;
        font-weight: bold;
    }
    }