@import "../../../App.scss";

.box-prodcut{
    .box-img{
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            width: 300px;
            height: 300px;
            border-radius: 50%;
        }
        .small-img{
            width: 220px !important;
            height: 220px !important;
            object-fit: contain;
            background: #F4F4F4;
        }
        
    }
    h5{
        font-weight: bold;
        color: $main-color;
    }
    h6{
        color: #29303B;
        font-weight: bold;
        font-size: 20px;
        margin-top: 20px;
        
        span{
            color: gray;
            font-weight: 500;
            font-size: 18px;
            margin-left: 5px;
        }
        .Available{
            color: #222;
            background-color: rgba(48, 235, 48, 0.523);
            padding: 5px;
            border-radius: 8px;
        }
    }
    p{
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        color: #29303B;
    }
    .table{
        td,tr,th{
            border: solid #000;
        }
        tr:nth-child(odd) {
            background-color: rgb(219, 218, 218);
        }
    }
}