@import '../../../App.scss';
.order-detalis{
    .box-man{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        margin-top: 25px;
        margin-bottom: 10px;
        .box-card{
            // border: 3px solid $main-color;
            box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 5px;
            padding: 10px 20px;
            border-radius: 8px;
            position: relative;
            width: 100%;
            h6{
                position: absolute;
                top: -18px;
                left: 1px;
                box-shadow:0px -6px 14px 0px rgba(201, 201, 201, 0.47);
                padding: 0 10px;
                background-color: #fff;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
            img{
                width: 55px;
                height: 55px;
                border-radius: 50%; 
            }
            .content-card{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;
                p{
                    font-size: 12px;
                    font-weight: 900;
                    margin-bottom: 2px;
                    color: $main-color;
                    .span-card{
                        font-weight: 600 !important;
                        font-size: 13px !important;
                        color: #666 !important;
                    }
                    
                }
            }
        }
    }
    .box-location{
        position: relative;
        margin-top: 50px  !important;
        box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
        padding: 20px  !important;
        border-radius: 8px;
        h6{
            position: absolute;
            top: -18px;
            left: 1px;
            box-shadow:0px -6px 14px 0px rgba(201, 201, 201, 0.47);
            padding: 0 10px;
            background-color: #fff;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: fit-content;
        }
        .box-content{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid lightgray;
            margin-bottom: 25px !important;
            padding-bottom: 5px;
            margin-top: 15px;
            .title{
                color: #000;
                font-weight: bold;
                font-size: 14px;
                margin-right: 5px;
            }
            .title-2{
                color: gray;
                font-weight: 700;
                font-size: 14px;
            }
        }
    }

    .box-order-right{
        position: relative;
        margin-top: 25px  !important;
        box-shadow: 2px 4px 10px rgba(201, 201, 201, 0.47);
        padding: 12px  !important;
        border-radius: 8px;
        h6{
            position: absolute;
            top: -18px;
            left: 1px;
            box-shadow:0px -6px 14px 0px rgba(201, 201, 201, 0.47);
            padding: 0 10px;
            background-color: #fff;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            width: fit-content;
        }
        .box-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lightgray;
            margin-bottom: 25px !important;
            padding-bottom: 5px;
            margin-top: 15px;
            .title{
                color: #000;
                font-weight: bold;
                font-size: 14px;
                margin-right: 5px;
            }
            .title-2{
                color: gray;
                font-weight: 700;
                font-size: 14px;
            }
            .total{
                color: $main-color;
            }
        }
    }

    .table{
        margin-top: 30px;
        .black-color{
            height: 30px !important;
        }
        td,tr{
            border: solid #0000003b;
           font-weight:900;
            font-size: 13px;
            padding-left: 10px;
            padding-right: 10px;
        }
        tr:nth-child(odd) {
            background-color: $main-color;
        }
    }
}