.box-inps{
    .upload-image-box{
        input{
            display: none;
        }
        .upload-box{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            img{
                max-width: 400px;
                max-height: 400px;
                @media (max-width: 400px) {
                    max-width: 250px !important;
                }
            }
            .big-upload{
                flex: 3;
                width: 400px;
                height: 400px;
                background-color: rgb(240, 238, 238);
                border: 1px solid lightgray;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                svg{
                    color: #a7a3a3;
                    height: 70px;
                    width: 70px;
                }
            }
            .small-upload{
                display: flex;
                background-color: gray;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                padding: 10px;
                cursor: pointer;
                svg{
                    color: #fff;
                } 
            }
        }
        
    }
    .switch{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }
}