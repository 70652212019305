@import '../../../App.scss';

.uplaod-image-group {
    .form-group{
        width: 100%;
        small{    
            margin-bottom: 5px;
            font-size: 12px;
        }
    }

    .input-group{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .custom-file{
            flex: 3;
            border: 1px solid lightgray;
            padding: 5px;
            border-radius: 5px;
            border-top-right-radius: 5px !important;
            border-bottom-right-radius: 5px !important;
            input{
                display: none;
            }
            .custom-file-label{
                width: 100%;
                cursor: pointer;
            }
        }
        .custom-upload{
            flex: 1;
            border: 1px solid lightgray;
            padding: 5px;
            border-radius: 5px;
            background-color: $main-color;
            color: #fff;
            text-align: center;
            cursor: pointer;
        }
    }

}

