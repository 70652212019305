@import '../../../App.scss';

.add-new{
    background-color: $main-color;
    color: #fff;
    float: right;
    transition: all 0.3s ease;
}
.add-new:hover{
    background-color: $main-color;
    color: #fff;
    float: right;
}