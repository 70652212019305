@import '../../../App.scss';

.action-box {
    .action{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        h6{
            font-weight: bold;
            margin-right: 5px;
            font-size: 22px;
        }
        .fav{
            background: crimson;
            padding: 5px 11px;
            color: #fff;
            font-weight: bold;
        }
        .addre{
            background: rgb(174, 184, 26);
            padding: 5px 11px;
            color: #fff;
            font-weight: bold;
        }
        .bat{
            background: $main-color;
            padding: 5px 11px;
            color: #fff;
            font-weight: bold;
        }
    }

    .box-view{
        margin-top: 60px !important;
        padding-bottom: 30px;
        .col-md-6{
            height: 30px !important;
        }
            .box-image{
                    width: 250px;
                    height: 250px;
                    margin-bottom: 20px;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                       
                    }
            }
            .Names{
                margin-left: 20px;
                h2{
                    font-weight: bold;
                    font-size: 22px;
                }
            }
            .icon-box{
                border-bottom: 1px solid lightgray;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 3px;
                padding-bottom: 6px;
                svg{
                    color: $main-color;
                }
                span{
                    font-size: 14px;
                }
            }
        }
}


