.box-vari{
    box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
    padding: 7px !important;
    border-radius: 10px;
    margin-bottom: 15px !important;
    position: relative;
    .box-icon{
        position: absolute;
        top: -6px;
        right: -6px;
        background-color: crimson;
        border-radius: 50%;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg{
            color: #fff;
            font-size: 16px;            
            font-weight: bold;
            
        }
    }
}