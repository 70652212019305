@import '../../../App.scss';

.input-edit-box{
    border : 1px solid lightgray;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    border-radius: 20px;
    input{
        border: none;
        border-bottom: 1px solid lightgray;
        width: 80%;
        padding-bottom: 5px;
    }
    p{
        margin-bottom: 0;
        font-size: 17px;
    }
    .icons{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 2px;
        margin-top: 2px;
        .edit-icon{
            color: #4F8640;
            cursor: pointer;

        }
        .edit-delete{
            color: crimson;
            cursor: pointer;

        }
        .edit-save{
            color: rgb(72, 72, 203);
            cursor: pointer;

        }
    }
}

.pop-up-delete{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: absolute;
    top: 35%;
    left: 4%;
    box-shadow: 2px 4px 10px 8px rgba(201, 201, 201, 0.47);
    z-index: 99999;
    background: #fff;
    padding: 20px 10px;
    border-radius: 20px;
    .btnleist-delte{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        .btn-n{
            background-color: gray;
            color: #fff;
            border: none;
            padding: 8px 30px;
        }
        .btn-y{
            background-color:crimson;
            color: #fff;
            border: none;
            padding: 8px 30px;  
        }
    }
}