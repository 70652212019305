@import '../../App.scss';
.action-det{
    margin-right: -10px;
    margin-right: -10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    svg{
        color: crimson;
        cursor: pointer;
    }
    .btn{
        font-size: 11px !important;

    }
    .mmk{
        background-color: #22b995;
        border: #22b995;
    }
    .icon-sub{
        margin-right: 5px;
        cursor: pointer;
    }
    .icon-sub-edit{
        color: #22b995 !important;
    }
}

.table-order{
    .icon-socket{
        color: #7aa02f;
        cursor: pointer;
    }
    .order-assign-icon{
        color: $second-color;
        cursor: pointer;
    }
    .order-assign-icon-disable{
        color: gray;
        cursor: not-allowed;
    }
    .icon-view{
        color:$main-color;
        cursor: pointer;
    }
    .alert-green{
        color: $main-color;
    }
    .alert-red{
        color: crimson;
    }
    .Pending{
        background-color: rgba(210, 105, 30, 0.427);
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Delivered{
        background-color: #498a37ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .OnDelivery{
        background-color: #0597f3ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Deliverd{
        background-color: #261457ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Cancelled{
        background-color: #ed0909ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Confirmed{
        background-color: #18ff3bae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Returned{
        background-color: #7e0909ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
    .Returned{
        background-color: #830505ae;
        padding: 6px 6px !important;
        border-radius: 5px;
        font-weight: bold;
        font-size: 13px;
        cursor: pointer;
        display: flex !important;
        justify-content: center;
        align-items: center;
        width: 80px !important;
    }
}


.curancy{
    font-weight: 900;
}