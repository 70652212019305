@import "../../App";

.loading-screen{
  background-color: $main-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: 200px;
    height: 200px;
  }
}