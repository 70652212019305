@import '../../App';

.big{
    height: 100vh !important;
    overflow-y: scroll !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 3px;
    position: sticky;
    top: 5px;
    background-color: $main-color;
    box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
    min-height: 100vh;
    overflow: scroll !important;
    height: 100vh;
    z-index: 999;
    scroll-behavior: $main-color;
  scrollbar-color: $main-color;
        @media (max-width: 1200px) {
            position: absolute;
            width: 290px;
            left: -290px;
            top: 65px;
        }
    .logo{
        width: 100%;
        height: 100%;
        // background-color: #2CD8AF;
        margin-bottom:20px ;
        margin-top: -3px;
        border-bottom: 1px solid $main-color;
        img{
            width: fit-content;
            height: 100%;
            padding-bottom: 5px;
            margin-left: 8px;
            // background-color: #2CD8AF;
        }

    }
    .img-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        h5{
            color: #fff;
            font-weight: bold;
            font-size: 27px;
            padding-bottom: 40px;
        }
      
    }
    .list{
        width: 100%;
        position: relative;
        img{
            width: fit-content;
            height: 90px;
            margin-bottom: 25px;
            padding-bottom: 20px;
        }
        hr{
            margin: 1rem 0;
            color: inherit;
            border: 0;
            border-top: 1px solid;
            opacity:1; 
            position: absolute;
            background: lightgray;
            height: 1px;
            border: 1px solid lightgray;
            width: 90%;
            bottom: 11px;
        }
        h6{
           color : #fff;
            font-weight: bold;
            font-size: 15px;
        }
       ul{
        padding: 0 6px;
        a,div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 5px;
            border-radius: 8px;
            padding: 5px;
            margin-bottom: 10px;
            font-size: 14px;
            cursor: pointer;
            color: #fff;
            gap: 4px;
            text-decoration: none;
            .icon-s{
                color: $second-color;
                margin-right: 5px;
            }
            
        }a.active,div.active,
            a:hover,div:hover{
                background-color: #fff;
                color: #000;
            svg{
                color: $second-color;
            }
        }
        }.themesfather{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-left: 30px;
            .theme{
            width: 20px;
            height: 20px;
            border: 1px solid $main-color ;
            cursor: pointer;
            border-radius: 5px;
        }.white{
            background-color: rgb(226, 240, 240);
        }.dark{
            background-color: #333;
        }
        }
        
    }
}
::-webkit-scrollbar{
    width: 5px;
}

::-webkit-scrollbar-track{
    background-color: #eee;
}

::-webkit-scrollbar-thumb{
    background-color: rgb(165, 160, 160);
}


.big2{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 3px;
    position: sticky;
    top: 5px;
    // border-right: 0.5px solid rgb(230, 227, 227);
    box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
    min-height: 100vh;
    background-color: $main-color;
    overflow: scroll !important;
    height: 100vh;
    z-index: 999;
    // transition: all 0.3s ease;
    /* Media query for screens up to 768px */
        @media (max-width: 1200px) {
            position: absolute;
            width: 290px;
            left: 6px;
            top: 65px;
        }
    .logo{
        width: 100%;
        height: 100%;
        // background-color: #2CD8AF;
        margin-bottom:20px ;
        margin-top: -3px;
        border-bottom: 1px solid $main-color;
        img{
            width: fit-content;
            height: 100%;
            padding-bottom: 5px;
            margin-left: 8px;
            // background-color: #2CD8AF;
        }

    }
    .img-logo{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        padding-left: 9px;
        h5{
            color: #fff;
            font-weight: bold;
            font-size: 27px;
            padding-bottom: 40px;
        }
      
    }
    .list{
        width: 100%;
        position: relative;
        img{
            width: fit-content;
            height: 90px;
            margin-bottom: 25px;
            padding-bottom: 20px;
        }
        hr{
            margin: 1rem 0;
            color: inherit;
            border: 0;
            border-top: 1px solid;
            opacity:1; 
            position: absolute;
            background: lightgray;
            height: 1px;
            border: 1px solid lightgray;
            width: 90%;
            bottom: 11px;
        }
        h6{
           color : #fff;
            font-weight: bold;
            font-size: 15px;
        }
       ul{
        padding: 0 6px;
        a,div{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 5px;
            border-radius: 8px;
            padding: 5px;
            margin-bottom: 10px;
            font-size: 14px;
            gap: 4px;
            cursor: pointer;
            color: #fff;
            text-decoration: none;
            .icon-s{
                color: $second-color;
                margin-right: 5px;
            }
            
        }a.active,div.active,
            a:hover,div:hover{
                background-color: #fff;
                color: #000;
            svg{
                color: $second-color;
            }
        }
        }.themesfather{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            margin-left: 30px;
            .theme{
            width: 20px;
            height: 20px;
            border: 1px solid $main-color ;
            cursor: pointer;
            border-radius: 5px;
        }.white{
            background-color: rgb(226, 240, 240);
        }.dark{
            background-color: #333;
        }
        }
        
    }
}

/* Define a CSS class for the menu with transitions */
/* Define a CSS class for the menu with opacity transitions */
.menu-list {
    opacity: 0; /* Initially, hide the menu */
    max-height: 0; /* Hide content */
    overflow: hidden;
    transition: opacity 0.3s ease-in-out /* Add transition effect */
  }
  
  /* When the menu is shown, adjust the opacity and max-height properties */
  .menu-list.active {
    opacity: 1;
    max-height: 100%;
    transition: opacity 0.3s ease-in-out /* Add transition effect */

  }


  
.big::-webkit-scrollbar {
    width: 4px; /* Adjust the width to your preference */
  }
  
  .big::-webkit-scrollbar-thumb {
    background-color: rgba(201, 201, 201, 0.47); /* Customize the scrollbar thumb color */
    border-radius: 4px; /* Customize the scrollbar thumb border radius */
  }
  
  .big::-webkit-scrollbar-track {
    background-color: transparent !important; /* Hide the scrollbar track when not scrolling */
  }
  
  /* Use 'scrollbar-width' to hide the scrollbar on Firefox */
  .big {
    scrollbar-width: thin;
  }


  .big2::-webkit-scrollbar {
    width: 4px; /* Adjust the width to your preference */
  }
  
  .big2::-webkit-scrollbar-thumb {
    background-color: rgba(201, 201, 201, 0.47); /* Customize the scrollbar thumb color */
    border-radius: 4px; /* Customize the scrollbar thumb border radius */
  }
  
  .big2::-webkit-scrollbar-track {
    background-color: transparent !important; /* Hide the scrollbar track when not scrolling */
  }
  
  /* Use 'scrollbar-width' to hide the scrollbar on Firefox */
  .big2 {
    scrollbar-width: thin;
  }