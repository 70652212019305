@import '../../../App.scss';
.radio-group{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
    padding-left: 10px;
    margin-bottom: 10px;
  
    .box-radio{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        label{
            font-size: 20px;
            font-weight: 600;
            cursor: pointer;
        }
        input[type='radio']:after {
            width: 20px;
            cursor: pointer;
            height: 20px;
            border-radius: 15px;
            top: -4px;
            left: -4px;
            position: relative;
            background-color: #d1d3d1;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
        }
    
        input[type='radio']:checked:after {
            width:20px;
            cursor: pointer;
            height:20px;
            border-radius: 15px;
            top: -4px;
            left: -4px;
            position: relative;
            background-color: $main-color;
            content: '';
            display: inline-block;
            visibility: visible;
            border: 2px solid white;
        }
    }
}