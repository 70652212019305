
@import '../../../App.scss';



.box-favo{
    margin-top: 20px;
    .card{
        margin-bottom: 15px;
        border-radius: 5px;
        box-shadow: 2px 4px 10px rgba(201,201,201,0.47);
        border: none;
        .box-location{
            width: 100%;
            height: 100%;
        }
        .img-box{
            width: 100%;
            height: 200px;
            img{
                width: 100%;
                height: 100%;
                border-radius: 5px;
                // object-fit: scale-down;
                background: #F4F4F4;
            }
        }
        .box-title{
                 padding: 20px 10px 5px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                gap: 2px;
                .head{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    h5{
                        font-weight: bold;
                        float: left;
                        margin-right: auto;
                        color: $Vmain-color;
                        font-size: 23px;
                        width: fit-content;
                    }
                    .price{
                        color: $main-color;
                        font-weight: bold !important;
                        margin-top: -5px;
                        font-size: 17px;
                    }
                }
               
                .detils{
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 5px;
                    p{
                        color: #fff;
                        font-size: 14px;
                        background-color: $main-color;
                        padding: 1px 10px;
                        font-weight: bold;
                        border-radius: 8px;
                    }
                }
                .description{
                    p{
                        font-size: 14px;
                        margin-bottom: 10px;
                    }
                }
        }
        .content{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            gap: 10px;
            padding: 20px 5px;
            .box-content{
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 5px;
                border-bottom: 1px solid lightgray;
                padding-bottom: 5px;
                .title{
                    font-weight: bold;
                    color: $main-color;
                    font-size: 15px;
                }
                .title-2{
                    font-weight: 500;
                    font-size: 13px;
                }
            }
        }
    }

}